import React from 'react';

import DottedRectangle from '../images/ui/dotted-square-2-xxl';
import Workear from '../images/home/workwear';
import ProtectiveEquipment from '../images/home/protective-equipment';
import MedicalEquipment from '../images/home/medical-equipment';

// import DottedRectangle from '../../assets/images/dotted-squares/dotted-square-2-xxl.png';
// import Workear from '../../assets/images/home/workwear.png';
// import ProtectiveEquipment from '../../assets/images/home/protective-equipment.png';
// import MedicalEquipment from '../../assets/images/home/medical-equipment.png';

import './products.css';

const Products = () => {
  return (
    <div className="my-24 flex-wrap">
      <div className="relative w-full">
        <h2 className="text-center mb-4 test-bold text-xs-h2 sm:text-sm-h2 md:text-md-h2 lg:text-md-h2 ">
          Products
        </h2>
        <div id="dottedSquareProducts" className="absolute bottom-0 right-0">
          {/*<img src={DottedRectangle} className="w-24" />*/}
          <DottedRectangle className="w-18 md:w-24" />
        </div>
      </div>

      <section className="relative  flex flex-col  justify-between items-center  md:flex-row w-full px-screen-xs sm:px-screen-sm md:px-screen-md lg:px-screen-lg xl:px-screen-xl my-10 ">
        <div
          id="carding"
          className="w-1/4 h-full xs:w-full sm:w-full md:w-full  rounded overflow-hidden shadow-xl mx-2"
        >
          {/*<img src={Workear} alt="workwear" className=" mt-4 w-full h-256" />*/}
          <Workear className=" mt-4 w-full h-256" />
          <div id="cube" className="py-8">
            <div className="px-6  mb-4">
              <h1 className="text-dark-grey font-bold text-xs-p sm:text-sm-p md:text-md-p lg:text-lg-p text-center">
                WORKWEAR
              </h1>
            </div>
          </div>
        </div>

        <div
          id="carding"
          className="w-1/4 h-full xs:w-full sm:w-full md:w-full  rounded overflow-hidden shadow-xl mx-2"
        >
          {/*<img
            src={ProtectiveEquipment}
            alt="protectiveEquipment"
            className=" mt-4 w-full h-256"
          />*/}
          <ProtectiveEquipment className=" mt-4 w-full h-256" />
          <div id="cube" className="py-8">
            <div className="px-6  mb-4">
              <h1 className="text-dark-grey font-bold text-xs-p sm:text-sm-p md:text-md-p lg:text-lg-p text-center">
                PROTECTIVE EQUIPMENT
              </h1>
            </div>
          </div>
        </div>

        <div
          id="carding"
          className="w-1/4 h-full xs:w-full sm:w-full md:w-full  rounded overflow-hidden shadow-xl mx-2"
        >
          {/*<img
            src={MedicalEquipment}
            alt="medicalEquipment"
            className=" mt-4 w-full h-256"
          />
          */}
          <MedicalEquipment className=" mt-4 w-full h-256" />
          <div id="cube" className="py-8">
            <div className="px-6  mb-4">
              <h1 className="text-dark-grey font-bold text-xs-p sm:text-sm-p md:text-md-p lg:text-lg-p text-center">
                MEDICAL EQUIPMENT
              </h1>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Products;
