import React from 'react';
import SEO from '../components/seo';
import Hero from '../components/home/hero';
import OurCompany from '../components/home/our-company';
import Customisation from '../components/home/customisation';
import Banner from '../components/home/banner';
import Products from '../components/home/products';
import './index.css';

const Home = () => {
  return (
    <>
      <SEO title="Home" />
      {/* Hero */}
      <Hero />
      {/* Sections */}
      <div className="flex flex-wrap w-full">
        {/* OurCompany */}
        <div>
          <OurCompany />
        </div>

        {/* Costumization */}
        {/*<div>
          <Customisation />
        </div>*/}
      </div>
      {/* Banner */}
      <Banner />
      {/* Products */}
      <div className="w-full md:w-1/2 md:px-8" className="w-full">
        <Products />
      </div>
    </>
  );
};

export default Home;
