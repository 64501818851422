import React from 'react';
import './our-company.css';

//import OurCompanyImg from '../../assets/images/home/our-company.png';
//import DottedSquare from '../../assets/images/dotted-squares/dotted-square-1-xxl.png';
import OurCompanyImg from '../images/home/our-company';
import DottedSquare from '../images/ui/dotted-square-1-xxl';

const OurCompany = () => {
  return (
    <section className="w-full flex flex-row justify-center px-screen-xs sm:px-screen-sm md:px-screen-md lg:px-screen-lg xl:px-screen-xl my-12">
      <div className="w-full xl:w-2/3 flex flex-col md:flex-row justify-center">
        <div className="relative w-full md:w-1/2 border border-light-gray shadow-2xl">
          <div className="w-full my-12">
            <h5 className="text-center text-xs-h5 sm:text-sm-h5 md:text-md-h5 lg:text-md-h5">
              OUR COMPANY
            </h5>
          </div>
          <div id="descriptionContainer" className="mx-8 my-24">
            <p
              id="description"
              className="text-left text-dark-gray text-xs-p sm:text-xs-p md:text-xs-p lg:text-md-p"
            >
              LiNEFORYOU is a manufacturer company of workwear, protective
              equipment and medical equipment that has been distinguished in the
              global market by its dynamism to meet the special needs of all
              kinds of customers, not only with competent consulting services,
              but also with elaborate customised solutions. The main mission is
              to care and protect the workers and professionals of the threats
              across the several dangerous, hard and demanding working
              environments.
            </p>
          </div>
          <div className="w-full flex justify-center my-12">
            <button className="text-white text-xs-p sm:text-sm-p md:text-md-p lg:text-lg-p bg-secondary p-4 rounded">
              Learn More
            </button>
          </div>
          <div
            id="dottedSquareOurCompany"
            className="hidden md:block absolute bottom-0 right-0"
          >
            {/*<img src={DottedSquare} className="w-32" />*/}
            <DottedSquare className="w-32" />
          </div>
        </div>
        <div id="ourCompanyImg" className="w-full md:w-1/2 -z-10">
          {/*<img src={OurCompanyImg} alt="ourCompanyImg" className="w-full" />*/}
          <OurCompanyImg className="w-full" />
        </div>
      </div>
    </section>
  );
};

export default OurCompany;
