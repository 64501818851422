import React from 'react';
//import BannerImg from '../../assets/images/home/banner.png';
import BannerImg from '../images/home/banner';
import './banner.css';

const Banner = () => {
  return (
    <section className="w-full h-full my-12">
      <div id="bannerImg">
        {/*<img src={BannerImg} className="w-full" alt="banner" />*/}
        <BannerImg className="w-full" />
      </div>
    </section>
  );
};

export default Banner;
