import React from 'react';

import HeroImg from '../../assets/images/home/hero.png';
//import DottedSquare from '../../assets/images/dotted-squares/dotted-square-1-xxl.png';
import DottedSquare from '../images/ui/dotted-square-1-xxl';

import './hero.css';
const Hero = () => {
  return (
    <section className="relative flex flex-row justify-end w-full px-screen-xs sm:px-screen-sm md:px-screen-md lg:px-screen-lg xl:px-screen-xl h-256 sm:h-352 md:h-512">
      <div id="dottedSquareHero" className="absolute bottom-0 right-0 z-20">
        <DottedSquare className="w-24" />
        {/*<img src={DottedSquare} className="w-24" />*/}
      </div>
      <div id="heroImg" className="absolute top-0 left-0 w-full">
        <div id="heroBackground" className="absolute top-0 left-0 -z-4"></div>
        <img src={HeroImg} alt="hero" className={'w-full -z-4 '} />
      </div>
      <div className="w-3/4 sm:w-3/4 lg:w-1/3 mt-4 sm:mt-6 md:mt-8 xl:mt-12 z-10">
        <h1 className="text-white text-xs-h3 sm:text-sm-h3 md:text-md-h3 lg:text-lg-h3 text-right mt-4 md:mt-12 lg:mt-18">
          Building high performance solutions to protect your workers
        </h1>
      </div>
    </section>
  );
};

export default Hero;
